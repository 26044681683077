<template>
  <LeaderboardVue />
</template>

<script>
import LeaderboardVue from '../../components/leaderboards.vue'

export default {
  name: 'Leaderboard',
  components: {
    LeaderboardVue
  }
}
</script>
